<template>
    <div class="sample_box">
        <div class="container">
            <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
            <br>
            <el-steps
                :active="status_text.step_status"
                class="sample-steps"
                finish-status="success">
                <el-step title="待样片上传"></el-step>
                <el-step title="待样片挑选"></el-step>
                <el-step title="待精片上传"></el-step>
                <el-step title="待留言"></el-step>
                <el-step title="待精片修改"></el-step>
                <el-step title="已完成"></el-step>
            </el-steps>
            <br>
            <!-- 样片列表 -->
            <sample-list
                :active-pic="active_pic"
                :active-name="activeName"
                :key="activeName"
                v-if="sample_status === 0 || sample_status === 2">
                <!--
                    上传、
                    选中、
                -->
                <template v-slot:submitData>
                    <!-- 上传相片按钮(摄影师) -->
                    <div style="color: #E6A23C" v-if="order_user!=user_id" >建议图片大小2M以内</div>
                    <el-upload
                        class="sample_upload"
                        ref="upload"
                        action="#"
                        :multiple="true"
                        :show-file-list="false"
                        :http-request="handleRequest"
                        v-if="order_user!=user_id">
                        <el-button slot="trigger" type="primary">
                            <span>上传照片</span>
                        </el-button>
                    </el-upload>
                    <!-- 个人用户 -->
                    <el-button
                        type="warning"
                        class="sample_submit"
                        @click="handleSubmitSelect"
                        v-else>
                        <span>完成挑选</span>
                    </el-button>
                </template>
                <!--
                    完成样片上传、
                    完成样片挑选、
                -->
                <template v-slot:finish>
                    <el-button
                        type="warning"
                        @click="handleStatus(1)"
                        v-if="order_user!=user_id">
                        <!-- <span>完成挑选</span> -->
                        <span>完成上传</span>
                    </el-button>
                </template>
                <!-- 当前状态 -->
                <!-- <template v-slot:status>
                    <span v-if="sample_status==0 || sample_status==2">{{status_text.status}}</span>
                    <span v-else-if="sample_status==4 || sample_status==6">完成样片最终选择，待精修照片上传</span>
                    <span v-else></span>
                </template> -->
                <!-- 备注 -->
                <template v-slot:tips>
                    <div class="sample_tip"
                        v-if="user_type == 1">
                        <div>最多可选总数量：{{max_select}}</div>
                        <div>已选数量：{{active_pic.length}}</div>
                    </div>
                </template>
                <!--
                    选中、
                    取消选中、
                -->
                <template v-slot:handleItem="item">
                    <el-button
                        type="text"
                        size="mini"
                        class="sample_album_edit_btn"
                        @click="handleSelect(item.id)"
                        v-if="order_user==user_id">
                        <span v-if="!active_pic.includes(item.id)">选中</span>
                        <span v-else>取消选中</span>
                    </el-button>
                    <el-button
                        type="text"
                        size="mini"
                        class="sample_album_edit_btn"
                        @click="handleDel(item.id)"
                        v-else-if="item.used==0">
                        <span>删除</span>
                    </el-button>
                </template>
            </sample-list>
            <!-- 精片列表 -->
            <sample-list
				:verdict="sample_status"
                :active-pic="active_pic"
                :active-name="activeName"
                :key="activeName"
                v-else>
                <!--
                    下载
                 -->
                <template #download>
                    <el-button
                        type="primary"
                        @click="downloadImage"
                        v-if="order_user==user_id">
                        <span>下载</span>
                    </el-button>
                </template>
                <!--
                    上传、
                    留言
                -->
                <template v-slot:submitData>
                    <div style="color: #E6A23C" v-if="order_user!=user_id">建议图片大小5M以内</div>
                    <!-- 上传相片按钮(摄影师) -->
                    <el-upload
                        class="sample_upload"
                        ref="upload"
                        action="#"
                        :multiple="true"
                        :show-file-list="false"
                        :http-request="handleChoice"
                        v-if="sample_status == 0 && order_user!=user_id">
                        <el-button slot="trigger" type="primary">
                            <span>上传照片</span>
                        </el-button>
                    </el-upload>
                    <!-- 完成订单 -->
                    <el-button
                        type="warning"
                        @click="handleStatus(99)"
                        v-if="order_user==user_id&&sample_status==6">
                        <span>确认所有精片修改</span>
                    </el-button>
                </template>
                <!--
                    完成精片上传、
                    完成留言
					v-if="order_user!=user_id||sample_status!=2 ||sample_status!=0"
                -->
                <template v-slot:finish>
                    <el-button
						v-if="order_user!=user_id||sample_status!=4"
                        type="warning"
                        @click="handleStatus(2)"><!--状态由2改为99,By Hans-->
                        <span v-if="order_user==user_id&&sample_status==6">完成留言</span>
                        <span v-else-if="sample_status==7">完成修改</span>
                        <span v-else>完成上传</span>
                    </el-button>
                </template>
                <!-- 当前状态 -->
                <!-- <template v-slot:status>
                    <span v-if="sample_status==4 || sample_status==6 || sample_status==7">{{status_text.status}}</span>
                </template> -->
                <!--
                    留言、
                    显示留言、
                    修改精修
                -->
                <template v-slot:handleItem="item">
                    <!-- 当前订单拥有者 -->
                    <el-button
                        type="text"
                        size="mini"
                        class="sample_album_edit_btn"
                        @click="handleRemarks(item.id)"
                        v-if="order_user==user_id"
                        :disabled="sample_status<6">
						<span>{{!item.remarks?'留言':' '}}</span>
                    </el-button>
                    <!-- 修改精修(摄影师) -->
                    <template v-else>
                        <el-upload
							v-if="sample_status!=6&&sample_status!=99"
                            action="#"
                            :show-file-list="false"
                            :http-request="($event) => handleModify($event,item.id)"
                            class="sample_upload">
                            <el-button
                                type="text"
                                size="mini">
                                <span>{{sample_status==4?"上传精片":"修改"}}</span>
                            </el-button>
                        </el-upload>
                        <el-button
							v-if="sample_status==0||sample_status==1"
                            type="text"
                            size="mini"
                            class="sample_album_edit_btn"
                            @click="handleDelChoice(item.id)">
                            <span>删除</span>
                        </el-button>
                    </template>
                    <el-popover
                        placement="top-start"
                        trigger="hover"
                        :content="item.remarks"
                        class="sample_popover"
                        v-if="item.remarks">
                        <div>
                            <span>{{item.uptime}}：</span>
                            <span>{{item.remarks}}</span>
                        </div>
                        <el-button
                            slot="reference"
                            type="text"
                            size="mini">
                            <span>{{order_user==user_id? '查看': '留言'}}</span>
                        </el-button>
                    </el-popover>
                </template>
            </sample-list>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import OSS from "ali-oss";
import { picUtils } from "@/tool/picUtils";

export default {
    mixins: [picUtils],
    data() {
        return {
            breadcrumb: [
                {
                    path: "",
                    path_name: "摄影订单",
                },
                {
                    path: "/sample",
                    path_name: "样片",
                },
            ],
            activeName: "0", // 标签页参数
            active_pic: [], // 选中图片，
        }
    },
    computed: mapState({
        user_type: state => state.user.user_type,
        user_id: state => state.user.user_id,
        sample_list: state => state.order.sample_list,
        max_select: state => state.order.max_select,
        sample_status: state => state.order.sample_status,
        order_user: state => state.order.order_user,
        status_text(state) {
            let status = "", step_status = 6;
            switch (this.sample_status) {
                case 0:
                    status = "待样片上传";
                    step_status = 0;
                    break;
                case 2:
                    status = "待样片挑选";
                    step_status = 1;
                    break;
                case 4:
                    status = "待精片上传";
                    step_status = 2;
                    break;
                case 6:
                    status = "待留言";
                    step_status = 3;
                    break;
                case 7:
                    status = "待精片修改";
                    step_status = 4;
                    break;
                // default:
                //     status = this.sample_list.sampleStatus
            }
            return {
                status,
                step_status,
            }
        }
    }),
    components: {
        breadCrumb: () => import("@/components/breadCrumb"),
        sampleList: () => import('@/components/sampleList')
    },
	mounted() {
		console.log("状态码",this.sample_status)
	},
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            "writeDialogImgUrl"
        ]),
        ...mapActions([
            "getSampleList",
            "getChoiceList",
            "handleUploadSample",
            "handleUploadChoice",
            "handleSelectSample",
            "handleSampleStatus",
            "handleChoiceImage",
            "handleChoiceRemarks",
            "handleChoiceDel",
            "handleSampleDel"
        ]),
        handleSetActive(val){ // 初始化选中样片
            val.map((item,index,arr) => {
                if(item.used == 1 && !this.active_pic.includes(item.id)){
                    this.active_pic.push(item.id)
                }
            })
        },
        handleRequest(arg){ // 上传图片
            const isLt2M = arg.file.size / 1024 < 2048;
			// const isLt2M = arg.file.size;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2M!');
                return ;
            }
            this.handleBASE64(arg.file).then((res) => {
                this.handleUploadSample({
                    orderID: this.$route.query.id,
                    image: res,
                    name: arg.file.name
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                })
            })
            // this.handleSize(arg.file).then( size => {
            //     if(size.width < 1920){
            //         this.$message({
            //             showClose: true,
            //             message: "上传图片宽度不能小于1920px",
            //             type: "error"
            //         })
            //         return
            //     }
            // }).catch(() => {})
        },
        handleChoice(arg){ // 上传精修图片
            // const isLt2M = arg.file.size / 1024 > 5120;
			const isLt2M = arg.file.size;
            if (!isLt2M) {
                this.$message.error('上传精片需要大于 5M!');
                return ;
            }
            this.handleBASE64(arg.file).then(res => {
                this.handleUploadChoice({
                    orderID: this.$route.query.id,
                    image: res,
                    name: arg.file.name
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                })
            })

            // this.handleSize(arg.file).then( size => {
            //     if(size.width < 1920){
            //         this.$message({
            //             showClose: true,
            //             message: "上传图片宽度不能小于1920px",
            //             type: "error"
            //         })
            //         return
            //     }
            // }).catch(() => {})
        },
        handleSelect(id){ // 选中按钮
            let i = this.active_pic.indexOf(id);
            if(i<0 && this.active_pic.length >= this.max_select){
                this.$message({
                    showClose: true,
                    message: "当前选中数量已超过最多可选数量！",
                    type: "error"
                })
                return
            }else if(i<0){
                this.active_pic.push(id);
            }else{
                this.active_pic.splice(i,1);
            }
        },
        handleSubmitSelect(){ // 提交选中
            let sampleID = this.active_pic.join("|");
            this.handleSelectSample({
                sampleID,
                orderID: this.$route.query.id
            }).then(res => {
                // this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: res.type?"success":"error"
                // })
                if(res.type){
                    this.getSampleList(this.$route.query.id);
                    this.active_pic = [];
                    this.handleStatus(1);
                }
            })
            // this.$confirm("确定提交当前选中项吗？", {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            // }).catch(() => {})
        },
        handleStatus(arg){ // 编辑样片状态
            // arg: 1为样片  2为精片
            let orderID = this.$route.query.id,
            status = "";
            if(arg == 1 && this.order_user != this.user_id){ // 上传样片
                status = 2;
            }else if(arg == 1){ // 完成挑选
                status = 4;
            }else if(arg == 2 && this.order_user != this.user_id){ // 上传精片
                status = 6
            }else if(arg == 2){ // 完成留言
                status = 7;
            }else if(arg == 99){ // 完成订单
                status = 99;
            }
            this.$confirm("确定完成当前状态吗？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleSampleStatus({
                    status,
                    orderID
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                    if(res.type && arg == 1){
                        this.getSampleList(orderID)
                    }else if (res.type && (arg == 2 || arg == 99)){
                        this.getChoiceList(orderID)
                    }
                })
            }).catch(() => {})
        },
        handleDel(sampleID){ // 删除样片
            this.$confirm("是否确定删除当前样片？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleSampleDel({
                    orderID: this.$route.query.id,
                    sampleID
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                })
            }).catch(() => {})
        },
        handleDelChoice(choiceID) {
            this.$confirm("是否确定删除当前精片？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleChoiceDel({
                    orderID: this.$route.query.id,
                    choiceID
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                })
            }).catch(() => {})
        },
        handleModify(arg, id){ // 修改精修
            this.handleSize(arg.file).then( size => {
                // if(size.width < 1920){
                //     this.$message({
                //         showClose: true,
                //         message: "上传图片宽度不能小于1920px",
                //         type: "error"
                //     })
                //     return
                // }
                // const isLt2M = arg.file.size / 1024 < 2048;
                // if (!isLt2M) {
                //     this.$message.error('上传图片大小不能超过 2M!');
                //     return ;
                // }
                this.handleBASE64(arg.file).then(res => {
                    this.handleChoiceImage({
                        orderID: this.$route.query.id,
                        image: res,
                        choiceID: id
                    }).then(res => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.type?"success":"error"
                        })
                        if(res.type) this.getChoiceList(this.$route.query.id);

                    })
                })
            }).catch(() => {})
        },
        handleRemarks(choiceID){ // 留言
            let orderID = this.$route.query.id;
            this.$prompt("请输入备注", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.handleChoiceRemarks({
                    orderID,
                    choiceID,
                    remarks: value
                }).then( res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type?"success":"error"
                    })
                    if(res.type) this.getChoiceList(orderID);
                })
            }).catch(() => {})
        },
        downloadImage() {
            this.$alert('图片已存到服务器,以下图片点开可右键保存', '提示', {})
        }
    },
    created() {
    },
    watch: {
        sample_list: {
            handler: "handleSetActive",
            immediate: true
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            let query = to.query,
            spath = vm.order_user == vm.user_id? '/center/ownphotography': '/center/photography';
            vm.breadcrumb[0].spath = spath;
			    vm.getChoiceList(query.id);
            if(query.status == 0 || query.status == 2){
				console.log(query.status)
                vm.getSampleList(query.id)
            }else{
                vm.getChoiceList(query.id);
            }
        })
    }
}
</script>

<style scoped>
/* 样片容器 */
.sample_box{
    margin: 50px 0;
}
/* 步骤条 */
.sample-steps >>> .el-step__head.is-success,
.sample-steps >>> .el-step__title.is-success{
    color: #8d9afc;
    border-color: #8d9afc;
}
/* 上传按钮 */
.sample_submit,
.sample_upload{
    margin-bottom: 10px;
}
.sample_upload{
    display: inline-block;
    margin-right: 10px;
}
.sample_tip{
    margin-bottom: 10px;
    color: #909399;
}
/* 备注弹出框 */
.sample_popover{
    margin-left: 5px;
}
</style>
